import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
// styles
import itemStyles from '../components/item/cases.module.css';
import styles from './styles.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import FitnessAppCalculatorBanner from 'components/fitness-app-calculator/fitness-app-calculator-banner';
//other
import { meta } from '../../../metaData';

function GymClubCase() {
  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "gym-club-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "gym-club-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "gym-club-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Fitness Software', 'Ukraine', '2019', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Gym Management System"
      subTitle="Delivering a next-gen gym experience with a custom-built hardware & software package"
      metaData={meta.gymClub}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Our client came to us with the idea of creating the best gym experience possible. To
          accomplish this goal and beat out the competition, he requested custom gym management
          software with smart turnstiles to make going to the gym more enjoyable.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          There are over 200,000 gyms worldwide, and that number is growing every year. As the 2020
          pandemic showed us, it’s always important to stay up-to-date on the latest trends,
          including technology. Luckily for our client, the future-proofing process started from the
          get-go.
        </p>
        <p className={itemStyles.paragraph}>
          His idea was to create custom gym management software to handle the day-to-day
          relationships with clients. This included access control to certain gym areas, payment
          processing, and a suite of tools to manage the gym and the in-house fitness cafe.
        </p>
        <p className={itemStyles.paragraph}>
          When we got on board, the gym was in the first stages of construction. This made it easier
          to plan out what we would need hardware-wise and also gave us a chance to flesh out how
          everything would come together in the end.
        </p>
        <p className={itemStyles.paragraph}>
          Due to a lack of documentation, we couldn’t start development without in-depth research.
          After gathering the requirements, we worked out a plan and put it into action.
        </p>
        <Resume services={['product']} technology={['nodejs', 'react']} />
        <h2 className={itemStyles.title}>Team composition</h2>
        <p className={itemStyles.paragraph}>
          The nature of the project was such that it required the coordinated efforts of three
          separate teams (hardware, software, system administration/DevOps), including contractors.
          In addition, we hired an electrical engineer, whose job would be to handle the
          digital-to-analog tech and manage the contractors. And the last piece of the puzzle was
          attaching a dedicated consultant to the project who helped with staff onboarding and
          relieved the initial stress of working with a brand new system.
        </p>
        <p className={itemStyles.paragraph}>
          We used the waterfall project management model and Agile stand-up meetings to solve issues
          quickly and get a clear picture of progress along the entire project timeline.
        </p>
        <Team
          color="#7F56DA"
          list={[
            { count: 3, title: '3-5 Developers\n(depending on\nthe project stage)' },
            { count: 1, title: 'QA\nengineer' },
            { count: 1, title: 'Electrical\nengineer' },
            { count: 1, title: 'Project\nmanager' },
            { count: 1, title: 'Business\nanalyst' },
            { count: 1, title: 'System administrator/\nDevOps' },
          ]}
        />
      </div>
      <div className={styles.fitnessCalcBanner}>
        <FitnessAppCalculatorBanner />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key challenges</h2>
        <p className={itemStyles.paragraph}>
          The research and business analysis we conducted showed three key areas that we’d need to
          tackle to make the project a success.
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletColor)}>
          <li>
            Hardware - lay power and internet cables, set up video surveillance, and install
            turnstiles for the access control systems.
          </li>
          <li>
            Software - develop a web app to streamline the gym management process from A to Z.
          </li>
          <li>
            Server-side - the client wanted an on-premise server for security reasons, so we had to
            prepare a server room, configure access, and deploy the necessary software.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          The client’s other goals were to have access to data for sales and marketing purposes,
          prevent employees from abusing the system by sneaking in friends and family through the
          back door, minimize staff errors, and manage operations from a single app.
        </p>
        <p className={itemStyles.paragraph}>
          We’ll go over the hardware and software elements we either had to build from scratch or
          modify to fit the project best.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Admin panel</h2>
        <p className={itemStyles.paragraph}>
          The nerve center of the gym is the admin panel. This is where administrators register new
          gym-goers, create and change membership plans, and also where the business owner can view
          financial reports and conduct marketing activities. And it’s also where the fitness cafe
          admins can make changes to the menu and prices accordingly.
        </p>
        <p className={itemStyles.paragraph}>
          Here are other features outlined in the requirements:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletColor)}>
          <li>Control of sales, subscriptions, and visits;</li>
          <li>Fitness club personnel management;</li>
          <li>Warehouse accounting;</li>
          <li>Custom analytics;</li>
        </ul>
        <p className={itemStyles.paragraph}>
          Although it didn’t show up in the initial set of requirements, our QA engineer came up
          with test cases where, for example, a gym customer suddenly has to leave after scanning
          their card (thereby subtracting a gym visit from their membership). In this and other
          cases, we implemented features to make it easy for admins to make quick changes in the
          admin panel without escalating the issue.
        </p>
        <p className={itemStyles.paragraph}>
          To make the admin panel secure, we designed it to have layers of administrative rights
          (user roles) to prevent human errors from causing damage to the system.
        </p>
        <h2 className={itemStyles.title}>Digital and physical access control</h2>
        <p className={itemStyles.paragraph}>
          The biggest challenge for us in this project was how to connect the physical and digital
          worlds. It may sound easy at first, but it’s fairly complicated. The hardware (turnstile)
          has to connect to the software through a digital-to-analog system after a customer swipes
          a magnetic key card that checks whether they have access to a particular gym area and then
          unlocks turnstile while simultaneously making a record of entry. Additionally, the key
          card.
        </p>
        <h2 className={itemStyles.title}>Payment processing integration and smart reports</h2>
        <p className={itemStyles.paragraph}>
          To streamline the gym experience, the client requested we add a payment processor
          integration with PoS terminals, so gym-goers can pay for anything they’ve swiped with
          their card after their workout. Since we could then easily access the transaction data, we
          suggested adding the ability to analyze customer preferences so our client can see custom
          reports in the admin panel based on this data.
        </p>
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          Brocoders created custom, turnkey gym management software that covered every requirement
          we had in front of us. We managed multiple dedicated teams and helped our client
          future-proof their business.
        </p>
        <p className={itemStyles.paragraph}>Powerful gym management tools that we custom-built:</p>
        <ul className={clsx(itemStyles.bullets, styles.bulletColor)}>
          <li>
            An admin panel that allows administrators to create new gym memberships, change prices
            and fitness cafe menu items and manage the gym and its personnel.
          </li>
          <li>
            Smart access control cross-checks the customer database to ensure gym-goers have the
            required membership plan to enter certain gym areas.
          </li>
          <li>
            Payment processing that connects to the admin panel to show customer spending habits at
            a glance, which menu items are the most popular, and provides custom reports.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          The next steps in development are to create new user acquisition channels through a web
          app where people can register for virtual and in-person classes. Although this was
          initially included in our development plan, the client decided to put this off. However,
          the 2020 pandemic made him revisit these plans, and he got in contact with us so we could
          start putting together requirements for the new project.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          When the gym first opened its doors over five years ago, it became an overnight success.
          People flocked to the gym in droves because it had the best customer experience for at
          least a hundred miles. It’s still open to this day and has cemented its place as the top
          (and also most expensive) gym in the area.
        </p>
        <p className={itemStyles.paragraph}>
          Our client got the custom software he needed to manage day-to-day operations, access to
          real-time data, reach his sales goals, and plan marketing campaigns for user acquisition
          and retention.
        </p>
        <div className={clsx(styles.resultBlock, itemStyles.lastItem)}>
          <div className={styles.item}>
            <div className={styles.badge}>5+</div>
            <div className={styles.text}>Years in business</div>
          </div>
          <div className={styles.item}>
            <div className={styles.badge}>30%</div>
            <div className={styles.text}>
              Profits are generated by additional services and products
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GymClubCase;
